//==============================================================================
//==============================================================================
import * as React from 'react';

import { subscribe } from '../../utilities/analytics/analytics-dispatcher';
import {
    AddToCartEvent,
    AddToWishlistEvent,
    CheckoutPaymentEvent,
    CheckoutShippingEvent,
    CheckoutStartEvent,
    ImpressionEvent,
    PDPVisitEvent,
    RemoveFromCartEvent,
    SingleProductEvent,
    TransactionEvent
} from '../../utilities/analytics/analytics-events';

import * as GA4 from './ga4-typings';

import { IAnalyticsGa4Data } from './analytics-ga4.data';
import { IAnalyticsGa4Props } from './analytics-ga4.props.autogenerated';

//==============================================================================
// INTERFACES
//==============================================================================
declare const dataLayer: any[] | undefined;

//----------------------------------------------------------
// Client-specific custom fields
//----------------------------------------------------------
interface ExtendedProductData extends GA4.GA4_Item {
    uom?: string;       // Unit of measure
    milling?: string;   // Milling grade
}

//==============================================================================
// CLASS DEFINITION
//==============================================================================
/**
 * AnalyticsGa4 component
 * @extends {React.PureComponent<IAnalyticsGa4Props<IAnalyticsGa4Data>>}
 */
class AnalyticsGa4 extends React.PureComponent<IAnalyticsGa4Props<IAnalyticsGa4Data>> {

    private SUBSCRIBER_NAME = 'GOOGLE_ANALYTICS_4';

    //----------------------------------------------------------
    // Subscribe to events
    // NOTE: Currently, events can only be emitted from the
    // client. If we run this in the constructor, it occurs
    // during SSR and is cached across page views. Singletons
    // that maintain state are kept in memory on the server,
    // making them dangerous.
    // In the future the analytics system will queue
    // up events emitted during SSR. When that happens, the
    // system will need to switch to data actions to prevent
    // subscriptions from persisting globally on the server.
    //----------------------------------------------------------
    public componentDidMount() {
        this.addToCart = this.addToCart.bind(this);
        this.removeFromCart = this.removeFromCart.bind(this);
        this.purchase = this.purchase.bind(this);
        this.addToWishlist = this.addToWishlist.bind(this);
        this.productDetailView = this.productDetailView.bind(this);
        this.impression = this.impression.bind(this);
        this.checkoutStart = this.checkoutStart.bind(this);
        this.checkoutShipping = this.checkoutShipping.bind(this);
        this.checkoutPayment = this.checkoutPayment.bind(this);

        subscribe(this.SUBSCRIBER_NAME, 'addToCart', this.addToCart);
        subscribe(this.SUBSCRIBER_NAME, 'removeFromCart', this.removeFromCart);
        subscribe(this.SUBSCRIBER_NAME, 'purchase', this.purchase);
        subscribe(this.SUBSCRIBER_NAME, 'addToWishlist', this.addToWishlist);
        subscribe(this.SUBSCRIBER_NAME, 'productDetailView', this.productDetailView);
        subscribe(this.SUBSCRIBER_NAME, 'impression', this.impression);
        subscribe(this.SUBSCRIBER_NAME, 'checkoutStart', this.checkoutStart);
        subscribe(this.SUBSCRIBER_NAME, 'checkoutShipping', this.checkoutShipping);
        subscribe(this.SUBSCRIBER_NAME, 'checkoutPayment', this.checkoutPayment);
    }

    //----------------------------------------------------------
    // No visuals are involved
    //----------------------------------------------------------
    public render(): null {
        return null;
    }

    //==============================================================================
    // Helpers
    //==============================================================================

    //----------------------------------------------------------
    // Convert generic event data to a GA4_Item record
    //----------------------------------------------------------
    private formatProduct(product: SingleProductEvent): ExtendedProductData {

        return {
            item_id: product.itemID,
            item_name: product.name,
            item_variant: product.variant,

            quantity: product.quantity,
            currency: product.currency,
            price: product.price,
            discount: product.discount,

            item_brand: product.brand,
            location_id: product.pickupInStore,

            // Custom: non-standard GA4
            uom: product.unitOfMeasure,
            milling: product.attributes?.CartLineMillGrade,
        };
    }

    //----------------------------------------------------------
    //----------------------------------------------------------
    private emit(event: unknown) {
        if (typeof dataLayer !== 'undefined') {
            dataLayer.push(event);
        }
    }

    //==============================================================================
    // EVENT HANDLERS
    //==============================================================================

    //----------------------------------------------------------
    // Add to Cart
    //----------------------------------------------------------
    private addToCart(data: AddToCartEvent): void {

        // Create an event rather than directly pushing to the data layer to enforce typing
        const event: GA4.AddToCartEvent = {
            event: 'add_to_cart',
            ecommerce: {
                currency: data.currency,
                value: (data.price || 0) * data.quantity,
                items: [this.formatProduct(data)],
            }
        };

        this.emit(event);
    }

    //----------------------------------------------------------
    // Remove from Cart
    //----------------------------------------------------------
    private removeFromCart(data: RemoveFromCartEvent): void {

        // Create an event rather than directly pushing to the data layer to enforce typing
        const event: GA4.RemoveFromCartEvent = {
            event: 'remove_from_cart',
            ecommerce: {
                currency: data.currency,
                value: (data.price || 0) * data.quantity,
                items: [this.formatProduct(data)],
            }
        };

        this.emit(event);
    }

    //----------------------------------------------------------
    // Purchase Complete
    //----------------------------------------------------------
    private purchase(data: TransactionEvent): void {

        // Create an event rather than directly pushing to the data layer to enforce typing
        const event: GA4.PurchaseEvent = {
            event: 'purchase',
            ecommerce: {
                transaction_id: data.id,
                currency: data.currency,
                value: data.total,
                shipping: data.shipping,
                tax: data.tax,
                coupon: data.coupons?.join('|'),
                items: data.lines.map(entry => this.formatProduct(entry)),
            }
        };

        this.emit(event);
    }

    //----------------------------------------------------------
    // Add to wishlist
    //----------------------------------------------------------
    private addToWishlist(data: AddToWishlistEvent): void {

        const event: GA4.AddToWishlistEvent = {
            event: 'add_to_wishlist',
            ecommerce: {
                currency: data.currency,
                value: (data.price || 0) * data.quantity,
                items: [this.formatProduct(data)],
            }
        };

        this.emit(event);
    }

    //----------------------------------------------------------
    // Product Detail View (PDP visited)
    //----------------------------------------------------------
    private productDetailView(data: PDPVisitEvent): void {

        const event: GA4.ProductViewEvent = {
            event: 'view_item',
            ecommerce: {
                currency: data.currency,
                value: data.price,
                items: [this.formatProduct(data)],
            }
        };

        this.emit(event);
    }

    //----------------------------------------------------------
    // Product Impressions
    // Currently used for PLPs and product collections,
    // so we'll use the view_item_list event
    //----------------------------------------------------------
    private impression(data: ImpressionEvent): void {

        const event: GA4.ProductListViewEvent = {
            event: 'view_item_list',
            ecommerce: {
                item_list_id: data.list,
                item_list_name: data.context,
                items: data.products.map(entry => this.formatProduct(entry)),
            }
        };

        this.emit(event);
    }

    //----------------------------------------------------------
    // Checkout Start
    //----------------------------------------------------------
    private checkoutStart(data: CheckoutStartEvent): void {

        const event: GA4.CheckoutStartEvent = {
            event: 'begin_checkout',
            ecommerce: {
                currency: data.currency,
                value: data.subtotal,
                items: data.lines.map(entry => this.formatProduct(entry)),
            }
        };

        this.emit(event);
    }

    //----------------------------------------------------------
    // Checkout Start
    //----------------------------------------------------------
    private checkoutShipping(data: CheckoutShippingEvent): void {

        const event: GA4.CheckoutShippingEvent = {
            event: 'add_shipping_info',
            ecommerce: {
                shipping_tier: data.shippingMethod,
                currency: data.currency,
                value: data.shippingCost,
                coupon: data.coupon,
                items: data.lines?.map(entry => this.formatProduct(entry)),
            }
        };

        this.emit(event);
    }

    //----------------------------------------------------------
    // Checkout Start
    //----------------------------------------------------------
    private checkoutPayment(data: CheckoutPaymentEvent): void {

        const event: GA4.CheckoutPaymentEvent = {
            event: 'add_payment_info',
            ecommerce: {
                payment_type: data.paymentMethod,
                currency: data.currency,
                value: data.amount,
                items: data.lines?.map(entry => this.formatProduct(entry)),
            }
        };

        this.emit(event);
    }

}

export default AnalyticsGa4;
